import { FC, LegacyRef, Suspense, useRef } from 'react';
import Loading from '../../../components/Loader/DataLoader/DataLoader';
import { TweetsPageProps } from '../Model/Interfaces';
import styles from './LiveFeed.module.css';
import { Await } from 'react-router-dom';
import TweetCard from './TweetCard/TweetCard';

type LiveFeedProps = {
    tweetsData: TweetsPageProps;
    feedRef: LegacyRef<HTMLDivElement> | undefined;
    isDesktopLiveFeed: boolean;
    isLoadingTweets: boolean;
    isInfiniteTweetsLoading: boolean;
    onScrolledToBottom: Function;
};

const LiveFeed: FC<LiveFeedProps> = ({
    tweetsData,
    feedRef,
    isDesktopLiveFeed,
    isLoadingTweets,
    isInfiniteTweetsLoading,
    onScrolledToBottom
}) => {    
    let noTweetsUI: any = null;

    if (!isInfiniteTweetsLoading && tweetsData?.tweets?.length === 0) {
        noTweetsUI = <span className="block text-center text-md font-Inter font-medium text-themeBlue">No Tweets</span>;
    }

    const desktopLiveFeedUI = isDesktopLiveFeed && (
        <>
            <h1 className="my-0 mb-6 flex items-center">
                Live feed
                <img className="ml-4" alt="Blink Loader" style={{ maxHeight: '20px' }} src={'blinking-dot.gif'} />
            </h1>
            {isLoadingTweets ? (
                <TweetsLoader />
            ) : (
                <div className={`${styles.LiveFeedContainer} md:max-h-[100vh] md:overflow-y-scroll`}>
                    <Suspense fallback={<Loading />}>
                        <Await
                            resolve={tweetsData}
                            errorElement={<div>Error loading tweets...</div>}
                            children={(res: TweetsPageProps) => (
                                <>
                                    {res.tweets.map((tweet, tweetIndex) => (
                                        <TweetCard
                                            key={tweetIndex}
                                            tweet={tweet}
                                            onScrolledToBottom={onScrolledToBottom}
                                            isLastTweet={tweetsData.tweets.length - 1 === tweetIndex}
                                        />
                                    ))}
                                    {noTweetsUI}
                                    {isInfiniteTweetsLoading && <TweetsLoader />}
                                </>
                            )}></Await>
                    </Suspense>
                </div>
            )}
        </>
    );

    return (
        <div
            id="live-feed"
            ref={feedRef}
            className="flex-1 p-4 md:pl-0 overflow-y-auto hide-scrollbar hidden md:inline-block">
            {desktopLiveFeedUI}
        </div>
    );
};

const TweetsLoader = () => {
    return (
        <div className="flex justify-center items-center h-full mt-4">
            <Loading />
        </div>
    );
};

export default LiveFeed;
