import { FC } from 'react';
import { Magic } from 'magic-sdk';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { client } from '../../../utils/client';
import { CURRENT_YEAR } from '../../../utils/date';
import isEmail from 'validator/lib/isEmail';
import LdsRoller from '../../Loader/LdsRoller/LdsRoller';
import LoginErrorUI from './LoginErrorUI/LoginErrorUI';
import { PRODUCT_NAME } from '../../../utils/constants/app';
import { magicPublishableKey } from '../../../config';

const magic = new Magic(magicPublishableKey);

export const Login: FC = () => {
    const [isLoginRequestSent, setIsLoginRequestSent] = useState(false);
    const [email, setEmail] = useState('');
    const [isRequiredError, setIsRequiredError] = useState(false);
    const [isInvalidEmailError, setIsInvalidEmailError] = useState(false);
    const [isLoginError, setIsLoginError] = useState<any>(null);
    const navigate = useNavigate();

    const onInputChangeHandler = (event: any) => {
        const email = event.target.value;

        setIsInvalidEmailError(false);
        setIsRequiredError(false);

        if (!email) {
            setIsRequiredError(true);
        } else if (!isEmail(email)) {
            setIsInvalidEmailError(true);
        }

        setEmail(event.target.value.trim());
    };

    const onLoginHandler = async () => {
        try {
            setIsLoginRequestSent(true);
            await magic.auth.loginWithEmailOTP({ email });
            await client.get(`/auth`);
            navigate('/');
        } catch (error) {
            setIsLoginError(error);
            setIsLoginRequestSent(false);
        }
    };

    const onKeyPressLoginHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (isRequiredError || isInvalidEmailError || !email) {
            return;
        }

        if (event.key === 'Enter') {
            onLoginHandler();
        }
    };

    return (
        <>
            <section id="login" className="login-container mx-auto overflow-y-hidden">
                <div className="wrapper flex flex-col md:flex-row items-center mt-10 md:mt-0 md:justify-center">
                    <div className="w-full md:w-1/2 flex flex-col items-center">
                        <div className="w-full flex flex-col justify-center items-center">
                            <div className="min-w-[320px] md:max-w-[50%]">
                                <img src="/images/Login/LogoBlack.svg" alt="" width="150px" />
                                <p className="font-Inter text-themeBlue font-medium text-4xl mt-5">Log in</p>
                                <p className="font-Inter text-themeLightBlue text-base font-light mt-3">
                                    Welcome back! Please enter your details.
                                </p>

                                <div className="mt-8">
                                    <label htmlFor="email" className="text-inter font-medium text-sm">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="femail"
                                        name="email"
                                        placeholder="Enter your email"
                                        className="rounded-lg w-full mb-0"
                                        onChange={onInputChangeHandler}
                                        onKeyUp={onKeyPressLoginHandler}
                                    />
                                </div>
                                <LoginErrorUI
                                    isRequiredError={isRequiredError}
                                    isInvalidEmailError={isInvalidEmailError}
                                    isLoginError={isLoginError}
                                />

                                {!isLoginRequestSent ? (
                                    <button
                                        disabled={isRequiredError || isInvalidEmailError || !email}
                                        onClick={onLoginHandler}
                                        className={`w-full bg-themeOrange rounded-lg text-base font-semibold text-white mt-4 ${
                                            isRequiredError || isInvalidEmailError || !email
                                                ? `hover:bg-themeOrange hover:opacity-90 `
                                                : `hover:bg-buttonHover hover:transition-all cursor-pointer`
                                        }`}>
                                        Log in
                                    </button>
                                ) : (
                                    <LoginLoader />
                                )}
                                <NavLink
                                    to="/signup"
                                    className="font-Inter text-sm font-medium text-themeOrange flex justify-center my-8">
                                    {' '}
                                    <span className="font-Inter text-bBlue font-normal text-sm">
                                        Don’t have an account? &nbsp;
                                    </span>{' '}
                                    Sign up
                                </NavLink>
                            </div>
                        </div>

                        <div className="relative">
                            <p className="text-left text-themeLightBlue font-light text-sm fixed bottom-0 left-7 py-7">
                                © {PRODUCT_NAME} {CURRENT_YEAR}. All Right Reserved.
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 hidden md:block">
                        <img src="/images/Login/login.png" alt="" className="h-screen w-full" />
                    </div>
                </div>
            </section>
        </>
    );
};

const LoginLoader = () => {
    return (
        <div className="flex justify-center mt-4">
            <LdsRoller />
        </div>
    );
};
