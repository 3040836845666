import Axios from 'axios';
import { baseUrl, magicPublishableKey } from '../config';
import { Magic } from 'magic-sdk';
import { getAuthenticationToken, removeAuthenticationToken, setAuthenticationToken } from './authStorage';

export const client = Axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

const magic = new Magic(magicPublishableKey);

export const getAuthToken = async () => {
    try {
        let authToken = getAuthenticationToken();

        if (!authToken) {
            authToken = await magic.user.getIdToken({ lifespan: 3600 * 24 * 7 });
            setAuthenticationToken(authToken);
        }

        return authToken;
    } catch (e) {
        return null;
    }
};

export const getErrorMessage = (error: unknown) => {
    if (Axios.isAxiosError(error)) {
        return error.response?.data?.errorMessage || error.response?.data?.message || 'Something went wrong';
    }
    return (error as Error)?.message || 'Something went wrong';
};

export const getCsvUri = (type: string) => {
    const params = new URLSearchParams(window.location.search);
    const newUrl = new URL(baseUrl + '/csv/' + type);
    newUrl.search = params.toString();
    return newUrl.toString();
};

const tryLogout = async () => {
    try {
        await magic.user.logout();
    } catch (e) {
        console.warn(e);
    }
};

client.interceptors.request.use(
    async (config) => {
        const token = await getAuthToken();
        if (token) {
            (config as any).headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

client.interceptors.response.use(
    (success) => success,
    async (error) => {
        if (error.response?.status === 401) {
            removeAuthenticationToken();
            await tryLogout();
            window.location.href = '/login';
        } else if (String(error.response?.status).startsWith('4') && error.response?.status !== 404) {
            console.warn(error.response.data.errorMessage);
        }

        return Promise.reject(error);
    }
);
