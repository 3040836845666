import { createContext, useState } from 'react';

type HeaderContextType = {
    selectedSubMenu: null;
    setSelectedSubMenu: Function;
};

const HeaderContext = createContext<HeaderContextType>({
    selectedSubMenu: null,
    setSelectedSubMenu: function (menu: string) {}
});

export const HeaderContextProvider = ({ children }: React.PropsWithChildren) => {
    const [selectedSubMenu, setSelectedSubMenu] = useState(null);

    const context = {
        selectedSubMenu,
        setSelectedSubMenu
    };

    return <HeaderContext.Provider value={context}>{children}</HeaderContext.Provider>;
};

export default HeaderContext;
