import { FC } from 'react';

type LoginErrorProps = {
    isRequiredError: Boolean;
    isInvalidEmailError: Boolean;
    isLoginError: Boolean;
};

const LoginErrorUI: FC<LoginErrorProps> = ({ isRequiredError, isInvalidEmailError, isLoginError }) => {
    return (
        <>
            {isRequiredError && (
                <span className="font-Inter text-sm font-medium text-red-600 italic">
                    *Email is required to continue
                </span>
            )}
            {isInvalidEmailError && (
                <span className="font-Inter text-sm font-medium text-red-600 italic block">*Email is invalid</span>
            )}
            {isLoginError && (
                <span className="font-Inter text-sm font-medium text-red-600 italic block">
                    *Error occured while loggin in
                </span>
            )}
        </>
    );
};

export default LoginErrorUI;
