import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Magic } from 'magic-sdk';
import { useEffect, useState } from 'react';
import { client } from '../../utils/client';
import LdsRoller from '../../components/Loader/LdsRoller/LdsRoller';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { magicPublishableKey } from '../../config';

const magic = new Magic(magicPublishableKey);

export default function Layout() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const initializeLoginStatus = async () => {

            try {
                const response = await client.get(`/auth`);
                setUserData(response.data.user);
                setIsLoggedIn(true);
                // if (response.data.user?.subscriptionType === 'NONE' || !(response.data.user?.subscriptionType)) {
                //     window.location.href = (`https://buy.stripe.com/9AQ9EF14CfF1aC46oo?prefilled_email=${(response.data.user as any).email?.replace('@', '%40')}`)
                // }
            } catch (error) {
                navigate('/login');
            }
        };

        initializeLoginStatus();
    }, []);

    return isLoggedIn ? (
        <div className="flex flex-col justify-between h-full">
            <Header magic={magic} setUserData={setUserData} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn}
                    userData={userData} />

            <div className="max-w-7xl mx-auto w-full flex-1	">
                <Outlet />
            </div>

            <Footer />
        </div>
    ) : (
        <LoginLoader />
    );
}

const LoginLoader = () => {
    return (
        <div className="h-screen flex justify-center items-center">
            <LdsRoller />
        </div>
    );
};
