import Loading from '../../../components/Loader/DataLoader/DataLoader';
import styles from './Alerts.module.css';
import AlertsTableRow from './AlertsTableRow/AlertsTableRow';

type Props = {
    alerts: Array<any>;
    activeKeyword: string;
    onReset: Function;
    onKeywordSelect: Function;
    isLoadingAlerts: boolean;
    isLoadingTweets: boolean;
};

const Alerts = ({ alerts, activeKeyword, onReset, onKeywordSelect, isLoadingAlerts, isLoadingTweets }: Props) => {
    return (
        <div>
            <table
                className={`table-auto w-full ${styles.KeywordsDataTable} ${styles.scrollable}`}
                cellSpacing="0"
                cellPadding="0">
                <thead className="border-b-compBG ">
                    <tr>
                        <th className="text-greyState font-Inter font-normal text-xs w-1/4 text-left pl-0">Keyword</th>
                        <th className="text-greyState font-Inter font-normal w-1/4 text-xs">Tweet Number</th>
                        <th className="text-greyState font-Inter font-normal w-1/4 text-xs">Time</th>
                        <th className="text-greyState font-Inter font-normal w-1/4 text-xs">Alert notifications</th>
                    </tr>
                </thead>

                <tbody className="text-center">
                    {!isLoadingAlerts &&
                        alerts.map((alert, alertIndex) => (
                            <AlertsTableRow
                                alert={alert}
                                key={alertIndex}
                                active={alert.keyword === activeKeyword}
                                isLoadingTweets={isLoadingTweets}
                                onReset={onReset}
                                onKeywordSelect={onKeywordSelect}
                            />
                        ))}
                    {isLoadingAlerts && <AlertsLoader />}
                </tbody>
            </table>
        </div>
    );
};

const AlertsLoader = () => {
    return (
        <tr>
            <td>
                <div className="flex justify-center items-center h-full mt-4">
                    <Loading />
                </div>
            </td>
        </tr>
    );
};

export default Alerts;
