import { FC, useState } from "react";
import { Form, useActionData } from "react-router-dom";

type ApiKeysFormProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ApiKeysForm: FC<ApiKeysFormProps> = ({ isOpen, onClose }) => {

  const [key, setKey] = useState('');
  const [secret, setSecret] = useState('');
  const { error } = useActionData() as { error: string } ?? { error: null };

  if (!isOpen) return null;
  return (<Form method="post" className="w-full max-w-xl">
      <label htmlFor="key">API Key</label>
      <div className="flex flex-row justify-start items-center gap-4 mb-3 w-full">
        <div className="w-full">
          <input type="text" name="key" value={key} onChange={e => setKey(e.target.value)} className="mb-0 w-full" />
        </div>
      </div>
      <label htmlFor="secret">API Secret Key</label>
      <div className="flex flex-row justify-start items-center gap-4 mb-3 w-full">
        <div className="w-full">
          <input type="text" name="secret" value={secret} onChange={e => setSecret(e.target.value)} className="mb-0 w-full" />
        </div>
      </div>
      {error && <p>{error}</p>}
      <div className="flex flex-row justify-end items-center gap-4 mb-8">
        <button type="submit">Add</button>
        <button type="button" onClick={onClose} className="secondary">Cancel</button>
      </div>
  </Form>)
};
