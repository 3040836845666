import { FC, Suspense, useCallback, useReducer } from "react";
import { Await, defer, NavLink, useLoaderData, useRevalidator } from "react-router-dom";
import { CustomForm } from "../../components/CustomForm/CustomForm";
import { Search } from "../../components/Search/Search";
import { TweeterRenderer } from "../../components/TweeterRenderer/TweeterRenderer";
import { Tweeter } from "../../types/models";
import { client, getErrorMessage } from "../../utils/client";

type Response = {
  response: TweeterPageProps;
};

type TweeterPageProps = {
  tweeters: Tweeter[];
  next?: string;
};

const TweeterPage: FC = () => {
  const { response } = useLoaderData() as Response;
  const revalidator = useRevalidator();
  const [open, toggle] = useReducer((state: boolean) => !state, false);

  const handleDelete = useCallback((id: string, name: string) => () => {
    if (window.confirm(`Are you sure you want to delete ${name}?`)) {
      client.delete(`/admin/tweeter/${id}`).then(() => {
        revalidator.revalidate();
      }).catch((err) => {
        alert(getErrorMessage(err));
      });
    }
  }, [revalidator]);

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-4">
        <h1>Tweeters</h1>
        <div className="flex flex-row justify-end items-center gap-4">
          <Search />
          <button onClick={toggle}>Add new</button>
        </div>
      </div>
      <CustomForm isOpen={open} onClose={toggle} label="Usernames" bulk />
      <Suspense
        fallback={<p>Loading tweeters...</p>}
      >
        <Await
          resolve={response}
          errorElement={
            <p>Error loading tweeters!</p>
          }
        >
          {(res: TweeterPageProps) => (
            <>
              {res.tweeters.map((item, index) => (
                <div className="flex flex-row justify-between items-start gap-4 w-full mb-4" key={item.id}>
                  <div className="flex flex-row justify-start items-start gap-4">
                    <span className="text-gray-700">#{index + 1}.</span>
                    <TweeterRenderer tweeter={item} key={item.id} />
                  </div>
                  <div>
                    <button onClick={handleDelete(item.id, item.displayName)}>Delete</button>
                  </div>
                </div>
              ))}
              {res.next && (
                <NavLink to={`/tweeters?cursor=${res.next}`}>Load more</NavLink>
              )}
            </>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export const loadTweeters = async ({ request }: any) => {
  const url = new URL(request.url);
  const response = client.get(`/admin/tweeter${url.search}`).then(response => response.data);
  return defer({ response });
};

export const addTweeter = async ({ request }: { request: Request }) => {
  try {
    const formData = await request.formData();
    return await client.post('/admin/tweeter', { username: formData.get('value') }).then((res) => {
      alert('Submitted! It may take a while to show up!');
      return res;
    });
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};

export default TweeterPage;
