import { Dialog } from 'primereact/dialog';
import * as React from 'react';
import { FC } from 'react';
import { client, getErrorMessage } from '../../utils/client';
import { Group } from './GroupDialog';

type CreateGroupDialogProps = {
  isOpen: boolean;
  onClose: (group?: Group) => void;
  keyword: string;
};

export const CreateGroupDialog: FC<CreateGroupDialogProps> = ({
  isOpen,
  onClose,
  keyword,
}) => {
  const handleSubmit = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    client.post('/admin/keyword/groups', { keyword }).then((res) => {
      onClose(res.data);
    }).catch((e) => {
      console.error(e);
      alert(getErrorMessage(e));
    });
  }, [keyword, onClose]);

  return (
      <Dialog visible={isOpen} onHide={() => onClose()} header="Create group">
        <p>
          Create a new group and make "{keyword}" as the first and primary keyword.
        </p>
        <button onClick={handleSubmit} className="mt-3">Create</button>
      </Dialog>
  );
}
