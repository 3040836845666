import { FC, Suspense } from "react";
import { Await, defer, NavLink, useLoaderData } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { TweetRenderer } from "../../components/TweetRenderer/TweetRenderer";
import { useScoredQueryParams } from "../../hooks/useScoredQueryParams";
import { Tweet } from "../../types/models";
import { client, getCsvUri } from "../../utils/client";

type Response = {
  response: FeedPageProps;
};

type FeedPageProps = {
  tweets: Tweet[];
  next?: string;
};

const FeedPage: FC = () => {
  const { response } = useLoaderData() as Response;
  useScoredQueryParams();
  return (
    <>
      <div className="flex flex-row justify-between items-center gap-4">
        <h1>Feed</h1>
        <div className="flex flex-row justify-end items-center gap-4">
          <a href={getCsvUri('feed')} target="_blank" rel="noreferrer noopener">Export CSV</a>
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th>Date</th>
            <th>Account</th>
            <th>Account Link</th>
            <th>Tweet Text</th>
            <th>Tweet Link</th>
          </tr>
        </thead>
        <tbody>
        <Suspense
          fallback={<tr><td colSpan={99}><Loading /></td></tr>}
        >
        <Await
          resolve={response}
          errorElement={
            <tr><td colSpan={99}>Error loading tweets...</td></tr>
          }
        >
          {(res: FeedPageProps) => (
            <>
              {res.tweets?.length === 0 && (
                <tr>
                  <td colSpan={99}>No tweets found</td>
                </tr>
              )}
              {res.tweets.map(item => (
                <TweetRenderer tweet={item} key={item.id} />
              ))}
              {res.next && (
                <tr>
                  <td colSpan={99}>
                    <NavLink to={`/admin/feed?cursor=${res.next}`}>Load more</NavLink>
                  </td>
                </tr>
              )}
            </>
          )}
        </Await>
      </Suspense>
        </tbody>
      </table>
    </>
  );
};

export const loadFeed = async ({ request }: any) => {
  const url = new URL(request.url);
  const response = client.get(`/feed${url.search}`).then(response => response.data);
  return defer({ response });
};

export default FeedPage;
