import {
    CONTACT_US,
    FAQ,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
    TWITTER
} from '../../../../utils/constants/footer-urls';
import { hardReload } from '../../../../utils/url';

type MobileMenuProps = {
    isHamburgerMenuOpen: boolean;
    userData: any;
    onLogoutHandler: Function;
    onSubMenuSelectHandler: Function;
    onCloseMobileMenu: Function;
};

function MobileMenu({
    isHamburgerMenuOpen,
    userData,
    onLogoutHandler,
    onSubMenuSelectHandler,
    onCloseMobileMenu
}: MobileMenuProps) {
    return (
        <div
            id="menu"
            className={`${
                !isHamburgerMenuOpen ? 'hidden' : ''
            } absolute top-0 bottom-0 left-0 text-center bg-themeBlue flex-col self-end w-full min-h-screen py-1 pt-40 space-y-8 text-lg text-LightGray bg-EerieBlack z-10`}>
            <div className="group">
                <a href="#" onClick={hardReload}>
                    <img src="/images/Login/LogoWhite.svg" alt="" width="150px" className="mx-auto" />
                </a>
            </div>

            <div className="group">
                <a href="#" className="text-xl font-normal text-white" onClick={hardReload}>
                    Dashboard
                </a>
            </div>

            <div
                className="group"
                onClick={() => {
                    onSubMenuSelectHandler('alerts');
                    onCloseMobileMenu();
                }}>
                <span className="text-xl font-normal text-white cursor-pointer">Alerts</span>
            </div>

            <div className="group">
                {((userData as any)?.subscriptionType === 'BASIC' ||
                    (userData as any)?.subscriptionType === 'TRIAL') && (
                    <a
                        href={`https://billing.stripe.com/p/login/aEUaIcei33cA0zC5kk?prefilled_email=${(
                            userData as any
                        ).email?.replace('@', '%40')}`}
                        target="_blank"
                        className="text-xl font-normal text-white"
                        onClick={() => onCloseMobileMenu()}>
                        Manage subscription
                    </a>
                )}
            </div>

            <div className="group">
                <a href={TWITTER} className="text-xl font-normal text-white" onClick={() => onCloseMobileMenu()}>
                    Twitter
                </a>
            </div>

            <div className="group">
                <a href={FAQ} className="text-xl font-normal text-white" onClick={() => onCloseMobileMenu()}>
                    FAQ
                </a>
            </div>

            <div className="group">
                <a href={CONTACT_US} className="text-xl font-normal text-white" onClick={() => onCloseMobileMenu()}>
                    Contact us
                </a>
            </div>

            <div className="group">
                <a
                    href={TERMS_AND_CONDITIONS}
                    className="text-xl font-normal text-white"
                    onClick={() => onCloseMobileMenu()}>
                    Terms & Condition
                </a>
            </div>

            <div className="group">
                <a href={PRIVACY_POLICY} className="text-xl font-normal text-white" onClick={() => onCloseMobileMenu()}>
                    Privacy policy
                </a>
            </div>

            <div className="group">
                <a href="#" className="text-xl font-normal text-white" onClick={() => onCloseMobileMenu()}>
                    Cookies
                </a>
            </div>

            <div className="group">
                <a
                    href="#"
                    className="text-xl font-normal text-white"
                    onClick={() => {
                        onLogoutHandler();
                        onCloseMobileMenu();
                    }}>
                    Log out
                </a>
            </div>
        </div>
    );
}

export default MobileMenu;
