import { FC } from "react";
import { Form, useNavigation } from "react-router-dom";

export const Search: FC = () => {

  const navigation = useNavigation();

  const searching =
    navigation.location &&
    new URLSearchParams(navigation.location.search).has(
      "q"
    );

  return (<Form id="search-form" role="search" className="flex flex-row justify-end items-center gap-2">
    <input type="text" name="q" className={`mb-0 ${searching && 'text-slate-400'}`} placeholder="Search" />
  </Form>)
};
