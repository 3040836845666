import { FC } from 'react';

type SignupErrorProps = {
    isRequiredError: Boolean;
    isInvalidEmailError: Boolean;
    isSignupError: Boolean;
};

const SignupErrorUI: FC<SignupErrorProps> = ({ isRequiredError, isInvalidEmailError, isSignupError }) => {
    return (
        <>
            {isRequiredError && (
                <span className="font-Inter text-sm font-medium text-red-600 italic">
                    *Email is required to continue
                </span>
            )}
            {isInvalidEmailError && (
                <span className="font-Inter text-sm font-medium text-red-600 italic block">*Email is invalid</span>
            )}
            {isSignupError && (
                <span className="font-Inter text-sm font-medium text-red-600 italic block">
                    *Error occured while loggin in
                </span>
            )}
        </>
    );
};

export default SignupErrorUI;
