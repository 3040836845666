import { useEffect, useRef } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Tweet } from '../../../../types/models';

type Props = {
    tweet: Tweet;
    isLastTweet: boolean;
    onScrolledToBottom: Function;
};

function TweetCard({ tweet, isLastTweet, onScrolledToBottom }: Props) {
    const cardRef = useRef(null);

    useEffect(() => {
        if (!cardRef?.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (isLastTweet && entry.isIntersecting) {
                onScrolledToBottom();
                observer.unobserve(entry.target);
            }
        });

        observer.observe(cardRef.current);

        return () => {
            if(cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        }
    }, [isLastTweet]);

    return (
        <div ref={cardRef} className="min-h-[100px]">
            <TwitterTweetEmbed tweetId={tweet.id} />
        </div>
    );
}

export default TweetCard;
