import { FC, useMemo, useState } from "react";
import { ProgressBar } from 'primereact/progressbar';
import { ToggleButton } from 'primereact/togglebutton';
import { Button } from "primereact/button";
import { TwitterApiKey } from "../../types/models";

type TwitterApiKeyProps = {
  item: TwitterApiKey;
}

export const ApiKey: FC<TwitterApiKeyProps> = ({ item }) => {
  const value = useMemo(() => Math.floor(item.quotaRemaining/item.quota * 100), [item.quota, item.quotaRemaining]);
  const [enabled, setEnabled] = useState(item.enabled);
  return (
    <div className="flex flex-row justify-between items-center gap-4 mb-4 p-2 bg-gray-100">
      <div>{item.apiKey}</div>
      <div className="w-24">
        <ProgressBar value={value} className={`progress-bar-${item.apiKey}`} />
      </div>
      <div className="flex flex-row items-center gap-2">
        <ToggleButton checked={enabled} onChange={e => setEnabled(e.value)} onIcon="pi pi-check" offIcon="pi pi-times" onLabel="Enabled" offLabel="Disabled" className="p-button-sm" />
        <Button label="Delete" className="p-button-sm p-button-secondary" />
      </div>
    </div>
  );
};
