import { CURRENT_YEAR } from '../../../utils/date';
import { FAQ } from '../../../utils/constants/footer-urls';
import { PRIVACY_POLICY } from '../../../utils/constants/footer-urls';
import { TERMS_AND_CONDITIONS } from '../../../utils/constants/footer-urls';
import { CONTACT_US } from '../../../utils/constants/footer-urls';
import { TWITTER } from '../../../utils/constants/footer-urls';
import { hardReload } from '../../../utils/url';
import { PRODUCT_NAME } from '../../../utils/constants/app';

type Props = {};

export default function Footer({}: Props) {
    return (
        <footer className="hidden md:block">
            <div className="max-w-7xl flex flex-col px-6 py-6 md:px-0  md:flex-row md:justify-between mx-auto pt-10 pb-6 border-b-[1px] border-b-gray-200 mt-8">
                <div className="mb-4">
                    <img src="/images/Login/LogoBlack.svg" width="150px" alt="" className='cursor-pointer' onClick={hardReload} />
                </div>

                <div className="footer-list-container flex space-x-0 md:space-x-4 font-Inter font-medium text-base text-bBlue mt-4">
                    <div className="list-1 flex flex-col md:flex-row md:space-x-4 w-1/2 space-y-2 md:space-y-0">
                        <div>
                            <a href={TWITTER} rel="noreferrer" target="_blank" className="hover:opacity-80">
                                Twitter
                            </a>
                        </div>
                        <div>
                            <a href={FAQ} rel="noreferrer" target="_blank" className="hover:opacity-80">
                                FAQ
                            </a>
                        </div>

                        <div>
                            <a href="" className="hover:opacity-80">
                                Support
                            </a>
                        </div>
                    </div>


                    <div className="list-2 flex flex-col md:flex-row w-1/2 md:w-full md:space-x-4 space-y-2 md:space-y-0 ">
                        <div>
                            <a href={CONTACT_US} rel="noreferrer" className="hover:opacity-80">
                                Contact us
                            </a>
                        </div>

                        <div>
                            <a href={TWITTER} target="_blank" rel="noreferrer" className="hover:opacity-80">
                                Twitter
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-7xl flex flex-col-reverse md:flex-row md:justify-between mx-auto font-Inter font-light text-sm text-greyState px-6 py-8 md:px-0">
                <div>
                    <p>© {PRODUCT_NAME} {CURRENT_YEAR}. All Right Reserved.</p>
                </div>

                <div className="flex flex-row space-x-4 mb-3 md:mb-0">
                    <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">
                        Terms & Condition
                    </a>
                    <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
                        Privacy policy
                    </a>
                    <p>Cookies</p>
                </div>
            </div>
        </footer>
    );
}
