import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import TweetsPage, { loadTweets } from './pages/Admin/TweetsPage';
import MainPage, { loadTweets as loadMainTweets } from './pages/MainPage';
import HomePage from './components/HomePage/HomePage';
import FeedPage, { loadFeed } from './pages/Admin/FeedPage';
import IgnoredKeywordPage, { loadIgnoredKeywords } from './pages/Admin/IgnoredKeywordPage';
import { AdminTemplate } from './templates/AdminTemplate';
import TweeterPage, { addTweeter, loadTweeters } from './pages/Admin/TweeterPage';
import KeywordPage, { addKeyword, loadKeywords } from './pages/Admin/KeywordPage';
import ProbableKeywordPage, { addProbableKeyword, loadProbableKeywords } from './pages/Admin/ProbableKeywordPage';
import EfKeywordPage, { addEfProbableKeyword, loadEfProbableKeywords } from './pages/Admin/EfKeywordPage';
import KeywordGroupPage, { loadKeywordGroup } from './pages/Admin/KeywordGroupPage';
import KeysPage, { addApiKey, loadApiKeys } from './pages/Admin/KeysPage';
import BlacklistedKeywordPage, { loadBlacklistedKeywords } from './pages/Admin/BlacklistedKeywordPage';
import { Login } from './components/Authentication/Login/Login';
import { getInitialTweets } from './components/HomePage/DataLoader/Tweets';
import Layout from './templates/Layout/Layout';
import { Signup } from './components/Authentication/Signup/Signup';
import { HeaderContextProvider } from './store/HeaderContext';

export const router = createBrowserRouter([
    {
        errorElement: <ErrorPage />,
        children: [
            {
                element: (
                    <HeaderContextProvider>
                        <Layout />
                    </HeaderContextProvider>
                ),
                children: [
                    {
                        index: true,
                        loader: getInitialTweets,
                        element: <HomePage />
                    }
                ]
            },
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/signup',
                element: <Signup />
            },
            {
                path: '/admin',
                element: <AdminTemplate />,
                children: [
                    {
                        index: true,
                        loader: loadMainTweets,
                        element: <MainPage />
                    },
                    {
                        path: 'tweeters',
                        element: <TweeterPage />,
                        loader: loadTweeters,
                        action: addTweeter
                    },
                    {
                        path: 'tweets',
                        element: <TweetsPage />,
                        loader: loadTweets
                    },
                    {
                        path: 'feed',
                        element: <FeedPage />,
                        loader: loadFeed
                    },
                    {
                        path: 'keywords',
                        element: <KeywordPage />,
                        loader: loadKeywords,
                        action: addKeyword
                    },
                    {
                        path: 'keywords/ignored',
                        element: <IgnoredKeywordPage />,
                        loader: loadIgnoredKeywords,
                        action: addKeyword
                    },
                    {
                        path: 'keywords/blacklisted',
                        element: <BlacklistedKeywordPage />,
                        loader: loadBlacklistedKeywords,
                        action: addKeyword
                    },
                    {
                        path: 'keywords/probable',
                        element: <ProbableKeywordPage />,
                        loader: loadProbableKeywords,
                        action: addProbableKeyword
                    },
                    {
                        path: 'keywords/ef',
                        element: <EfKeywordPage />,
                        loader: loadEfProbableKeywords,
                        action: addEfProbableKeyword
                    },
                    {
                        path: 'keywords/group',
                        element: <KeywordGroupPage />,
                        loader: loadKeywordGroup
                    },
                    {
                        path: 'keys',
                        element: <KeysPage />,
                        loader: loadApiKeys,
                        action: addApiKey
                    }
                ]
            }
        ]
    }
]);
