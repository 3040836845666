import { FC, useCallback, useMemo, useState } from 'react';
import { useAsyncResource } from 'use-async-resource';
import { client } from '../../utils/client';
import { useIsScored } from '../../utils/store';
import { RenderStats, RenderStatsProps } from '../RenderStats/RenderStats';

const fetchStats = async (period: string, scored: boolean, isUnique: boolean) =>
    client
        .get<{ items: RenderStatsProps[] }>(`/stats?period=${period}${scored ? '&scored=true' : ''}&is_unique=${isUnique}`)
        .then((res) => res.data.items);
const fetchStatsNew = async (period: string, scored: boolean, isUnique: boolean) =>
    client
        .get<{ items: RenderStatsProps[] }>(`/stats-new?period=${period}${scored ? '&scored=true' : ''}&is_unique=${isUnique}`)
        .then((res) => res.data.items);

type StatsTableProps = {
    period: string;
    full?: boolean;
    activeKeyword: string;
    onReset?: () => void;
    onClick?: (keyword: string) => void;
    tab: 'tracked' | 'new';
    isUnique: boolean;
};

export const StatsTable: FC<StatsTableProps> = ({ tab, period, full, activeKeyword, onReset, onClick, isUnique }) => {

    const scored = useIsScored();
    const [dataReader] = useAsyncResource(tab === 'tracked' ? fetchStats : fetchStatsNew, period, scored, isUnique);
    const read = dataReader();

    const [sort, setSort] = useState<keyof RenderStatsProps>('change');
    const [direction, setDirection] = useState<'asc' | 'desc'>('desc');

    // sort items from read
    const items = useMemo(
        () =>
            read.sort((a, b) => {
                if (typeof a[sort] === 'number') {
                    return direction === 'asc' ? Number(a[sort]) - Number(b[sort]) : Number(b[sort]) - Number(a[sort]);
                } else {
                    return direction === 'asc'
                        ? String(a[sort]).localeCompare(String(b[sort]))
                        : String(b[sort]).localeCompare(String(a[sort]));
                }
            }) || [],
        [read, sort, direction]
    );

    const handleSort = useCallback((sort: keyof RenderStatsProps, direction: string) => {
        setSort(sort);
        setDirection(direction as 'asc' | 'desc');
    }, []);

    return (
        <>
            <table className="w-full">
                <thead>
                    <tr>
                        <th>
                            <SortLabel
                                sort="keyword"
                                label="Keyword"
                                onSortChange={handleSort}
                                currentSort={sort}
                                currentDirection={direction}
                            />
                        </th>
                        <th>
                            <SortLabel
                                sort="change"
                                label="Tweets count growth"
                                onSortChange={handleSort}
                                currentSort={sort}
                                currentDirection={direction}
                            />
                        </th>
                        <th>
                            <SortLabel
                                sort="mentions"
                                label="Tweet Number"
                                onSortChange={handleSort}
                                currentSort={sort}
                                currentDirection={direction}
                            />
                        </th>
                        {full && (
                            <>
                                <th>
                                    <SortLabel
                                        sort="changePercentage"
                                        label="Tweets count growth %"
                                        onSortChange={handleSort}
                                        currentSort={sort}
                                        currentDirection={direction}
                                    />
                                </th>
                                <th>
                                    <SortLabel
                                        sort="dominance"
                                        label="Dominance %"
                                        onSortChange={handleSort}
                                        currentSort={sort}
                                        currentDirection={direction}
                                    />
                                </th>
                                <th>
                                    <SortLabel
                                        sort="dominanceChange"
                                        label="Dominance Growth %"
                                        onSortChange={handleSort}
                                        currentSort={sort}
                                        currentDirection={direction}
                                    />
                                </th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {items.length === 0 && (
                        <tr>
                            <td colSpan={6}>No data...</td>
                        </tr>
                    )}
                    {items.map((item) => (
                        // @ts-ignore
                        <RenderStats
                            // @ts-ignore
                            allProperties={full}
                            // @ts-ignore
                            onReset={onReset}
                            // @ts-ignore
                            onClick={onClick}
                            key={item.keyword}
                            // @ts-ignore
                            active={item.keyword === activeKeyword}
                            {...item}
                        />
                    ))}
                </tbody>
            </table>
        </>
    );
};

type SortLabelProps = {
    sort: keyof RenderStatsProps;
    label: string;
    onSortChange: (sort: keyof RenderStatsProps, direction: 'asc' | 'desc') => void;
    currentSort: keyof RenderStatsProps;
    currentDirection: 'asc' | 'desc';
};

export const SortLabel: FC<SortLabelProps> = ({
    sort,
    currentSort,
    label,
    onSortChange: setSort,
    currentDirection
}) => {
    const active = useMemo(() => {
        if (sort === currentSort) {
            return currentDirection;
        }
        return false;
    }, [sort, currentSort, currentDirection]);

    const onClick = useCallback(() => {
        if (active) {
            setSort(sort, active === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(sort, 'desc');
        }
    }, [active, setSort, sort]);

    return (
        <button
            onClick={onClick}
            className={`p-0 m-0 text-black bg-transparent hover:bg-transparent hover:text-blue-400 ${
                active && 'text-blue-900'
            }`}>
            {label} {active && (active === 'asc' ? '▲' : '▼')}
        </button>
    );
};
