import { client } from '../../../utils/client';
import { defer } from 'react-router-dom';
// import { throttle } from 'lodash';

// Initial tweets data loader
export const getInitialTweets = async ({ request }: any) => {
    const url = new URL(request.url);

    // Not calling await to show loader with Suspense
    const response = client.get(`/tweets?limit=8`).then((response) => response.data);
    return defer({ response });
};

// TODO: DELETE IF ON SCROLL TWEET LOADER IN NOT NEEDED
// USAGE: BACKUP CODE FROM HOMEPAGE

// const onLoadMoreTweets = async () => {
//     console.log('loading more tweets', tweetsData);
//     if (tweetsData.next) {
//         let endpoint = `/tweets?cursor=${tweetsData.next}&period=${period}&keyword=${keyword}&limit=8`;
//         if (selectedTab === 'new') {
//             endpoint = `/feed?cursor=${tweetsData.next}&period=${period}&q=${keyword}&limit=8`;
//         }
//         let newTweets = await client.get(endpoint);
//         await setTweetsData({
//             ...tweetsData,
//             tweets: [...tweetsData.tweets, ...newTweets.data.tweets],
//             next: ''
//         });
//     }
// };

// useEffect(() => {
//     const handleScroll = async (event: { target: any }) => {
//         console.log('scrolling');
//         const target = event.target;
//         const atBottom = target.scrollHeight - target.scrollTop === target.clientHeight;
//         if (atBottom) {
//             await onLoadMoreTweets();
//         }
//     };

//     const throttledHandleScroll = throttle(handleScroll, 500);

//     if (feedRef.current) {
//         feedRef.current.addEventListener('scroll', throttledHandleScroll);
//     }

//     return () => {
//         if (feedRef.current) {
//             feedRef.current.removeEventListener('scroll', throttledHandleScroll);
//         }
//     };
// }, [tweetsData, period, keyword, feedRef]);
