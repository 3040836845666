import dayjs from "dayjs";
import { FC } from "react";
import { Tweet } from "../../types/models";

type TweetRendererProps = {
  tweet: Tweet;
};

export const TweetRenderer: FC<TweetRendererProps> = ({ tweet }) => (<tr>
  <td className="text-xs">{dayjs(tweet.tweetDate).format('lll')}</td>
  <td className="text-xs">
    <p>{tweet.tweeter.displayName}</p>
    <small>{tweet.tweeter.username}</small>
  </td>
  <td className="text-xs">
    <a href={`https://twitter.com/${tweet.tweeter.username}`} target="_blank" rel="noreferrer noopener">Open</a>
  </td>
  <td>{tweet.text}</td>
  <td className="text-xs">
    <a href={`https://twitter.com/${tweet.tweeter.username}/status/${tweet.id}`} target="_blank" rel="noreferrer noopener">Open</a>
  </td>
</tr>);
