import { FC, useReducer } from "react";
import { GroupDialog } from "./GroupDialog";

type GroupDialogButtonProps = {
  keyword: string;
};

export const GroupDialogButton: FC<GroupDialogButtonProps> = ({
  keyword,
}) => {
  const [edit, toggleEdit] = useReducer((s) => !s, false);
  return (
    <>
      <button onClick={toggleEdit}>Merge</button>
      {edit && (
        <GroupDialog isOpen={edit} onClose={toggleEdit} keyword={keyword} selectedValue={'0'} />
      )}
    </>
  );
};
