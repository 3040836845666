import { formatDateWithHoursMinutes } from '../../../../utils/date';
import styles from './AlertsTableRow.module.css';

type Props = {
    alert: any;
    active: boolean;
    onReset: Function;
    onKeywordSelect: Function;
    isLoadingTweets: boolean;
};

function AlertsTableRow({ alert, active, onReset, onKeywordSelect, isLoadingTweets }: Props) {
    const { keyword, mentions, date } = alert;
    const { time, date: uiDate } = formatDateWithHoursMinutes(date);

    return (
        <tr>
            <td className={`text-themeBlue font-inter font-normal text-sm text-left truncate ${styles.AlertTableFirstElement}`}>
                <span className="cursor-pointer border-b border-gray-300" onClick={() => isLoadingTweets ? undefined : onKeywordSelect(keyword, date)}>
                    {keyword}
                </span>
                {active && (
                    <button
                        className="cursor-pointer bg-transparent border-none p-0 m-0 text-black font-bold hover:bg-gray-100"
                        onClick={() => isLoadingTweets ? undefined : onReset()}>
                        (X)
                    </button>
                )}
            </td>

            <td>{mentions}</td>
            <td>
                {time},<span className="block text-xs md:text-sm">{uiDate}</span>
            </td>
            <td className="notifications">
                <a href="https://discord.com/" target="_blank" className="border-b-2 border-b-link text-link">
                    Join Discord
                </a>
            </td>
        </tr>
    );
}

export default AlertsTableRow;
