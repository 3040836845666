import { FC, Suspense, useCallback } from "react";
import { Await, defer, Link, NavLink, useLoaderData, useSubmit } from "react-router-dom";
import { Search } from "../../components/Search/Search";
import { client, getErrorMessage } from "../../utils/client";

type KeywordPageProps = {
  keywords: { keyword: string; mentions: number }[];
  next?: string;
};

type Response = {
  response: KeywordPageProps;
}

const EfKeywordPage: FC = () => {
  const { response } = useLoaderData() as Response;

  const submit = useSubmit();

  const handleAction = useCallback((act: string, id: string) => () => {
    const formData = new FormData();
    formData.append('keyword', id);
    if (act === 'ignore') {
      formData.append('ignore', 'true');
    }
    if (act === 'blacklist') {
      formData.append('blacklist', 'true');
    }
    submit(formData, { method: act === 'delete' ? 'delete' : 'put' });
  }, [submit]);

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-4">
        <h1>Filtered Probable Keywords</h1>
        <div className="flex flex-row justify-end items-center gap-4">
          <Search />
          <NavLink to="/admin/keywords/probable">Show all</NavLink>
        </div>
      </div>
      <Suspense
        fallback={<p>Loading keywords...</p>}
      >
        <Await
          resolve={response}
          errorElement={
            <p>Error loading keywords...</p>
          }
        >
          {(res: KeywordPageProps) => (
            <table className="w-full">
            <thead>
              <tr>
                <th>Keyword</th>
                <th>Mentions</th>
              </tr>
            </thead>
            <tbody>
            {res.keywords.map(item => (
              <tr key={item.keyword}>
                <td>
                  <Link to={`/admin/feed?q=${item.keyword}`} target="_blank">{item.keyword}</Link>
                </td>
                <td>
                  <div className="flex flex-row justify-between items-center">
                    <span>{item.mentions}</span>
                    <div className="flex flex-row justify-end items-center gap-2">
                      <button onClick={handleAction('confirm', item.keyword)}>Confirm</button>
                      <button onClick={handleAction('ignore', item.keyword)}>Ignore</button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            {res.next && (
              <tr>
                <td>
                  <NavLink to={`./admin/keywords/ef?cursor=${res.next}`}>Load more</NavLink>
                </td>
              </tr>
            )}
            </tbody>
          </table>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export const loadEfProbableKeywords = async ({ request }: any) => {
  const url = new URL(request.url);
  const response = client.get(`/admin/keyword/ef${url.search}`).then(response => response.data);
  return defer({ response });
};

export const addEfProbableKeyword = async ({ request }: { request: Request }) => {
  try {
    const formData = await request.formData();
    if (request.method.toLowerCase() === 'put') {
      return await client.put(`/admin/keyword/ef`, {
        keyword: formData.get('keyword'),
        ignore: formData.get('ignore') === 'true',
      });
    }
  } catch (err) {
    return { error: getErrorMessage(err) };
  }
};


export default EfKeywordPage;
