import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsScored } from "../utils/store";

export const useScoredQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const scored = useIsScored();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (scored) {
      searchParams.set('scored', 'true');
    } else {
      searchParams.delete('scored');
    }
    const search = searchParams.toString();
    if (search !== location.search) {
      navigate({ search }, { replace: true });
    }
  }, [location.search, navigate, scored]);
};
