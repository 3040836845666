import { FC } from "react";
import { Tweeter } from "../../types/models";

type TweeterRendererProps = {
  tweeter: Tweeter;
};

export const TweeterRenderer: FC<TweeterRendererProps> = ({ tweeter }) => (
  <a href={`https://twitter.com/${tweeter.username}`} className="flex flex-row justify-start items-center gap-4 mb-3" target="_blank" rel="noreferrer noopener">
    {tweeter.displayName}
    <small className="bg-gray-300 text-xs px-2">{tweeter.username}</small>
  </a>
);
