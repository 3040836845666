import { client } from '../../../utils/client';

export const getAlerts = async () => {
    let response = null;
    let error = null;

    try {
        response = await client.get(`/alerts`);
    } catch (error) {
        error = error;
    }
    return { error, response: response?.data?.items };
};
