import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children?: ReactNode;
    selectedTab: string;
    period: string;
    keyword: string;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { selectedTab, period, keyword } = prevProps;

        if (this.props.keyword !== keyword || this.props.selectedTab !== selectedTab || this.props.period !== period) {
            this.setState({ hasError: false });
        }
    }

    onResetErrorHandler = () => {
        this.setState({ hasError: false });
    };

    public render() {
        if (this.state.hasError) {
            return (
                <div className="flex flex-col justify-center items-center">
                    <h3 className="text-center">Sorry.. there was an error</h3>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
