import { FC, Suspense } from 'react';
import { TweetsPageProps } from '../Model/Interfaces';
import Loading from '../../../components/Loader/DataLoader/DataLoader';
import styles from './MobileLiveFeed.module.css';
import { Await } from 'react-router-dom';
import TweetCard from './TweetCard/TweetCard';

type MobileLiveFeedProps = {
    tweetsData: TweetsPageProps;
    isLoadingTweets: boolean;
    isInfiniteTweetsLoading: boolean;
    keyword: string;
    onResetKeywords: Function;
    onScrolledToBottom: Function;
};

const MobileLiveFeed: FC<MobileLiveFeedProps> = ({
    tweetsData,
    isLoadingTweets,
    keyword,
    onResetKeywords,
    isInfiniteTweetsLoading,
    onScrolledToBottom
}) => {
    let noTweetsUI: any = null;

    if (!isInfiniteTweetsLoading && tweetsData?.tweets?.length === 0) {
        noTweetsUI = <span className="block text-center text-md font-Inter font-medium text-themeBlue">No Tweets</span>;
    }

    return (
        <div id="liveFeed" className="tabcontent h-full md:hidden">
            <h1 className="my-0 flex items-center py-6 md:py-0">
                <span className="text-[26px] font-semibold">Live feed</span>
                <img className="ml-4" alt="Blink Loader" style={{ maxHeight: '20px' }} src={'blinking-dot.gif'} />
            </h1>

            {keyword && (
                <div className="inline-block mb-3">
                    <div className="border-[1px] border-gray-300 rounded-full px-4 py-2 flex space-x-2 shadow-sm items-center">
                        <p className="text-AzureBlue uppercase font-medium text-sm">{keyword}</p>
                        <span onClick={() => onResetKeywords()}>
                            <img src="/images/Login/Close.svg" alt="" />
                        </span>
                    </div>
                </div>
            )}

            {isLoadingTweets ? (
                <TweetsLoader />
            ) : (
                <div className={`${styles.LiveFeedContainer} max-h-[100vh] overflow-y-scroll`}>
                    <Suspense fallback={<Loading />}>
                        <Await
                            resolve={tweetsData}
                            errorElement={<div>Error loading tweets...</div>}
                            children={(res: TweetsPageProps) => (
                                <>
                                    {res.tweets.map((tweet, tweetIndex) => (
                                        <TweetCard
                                            key={tweetIndex}
                                            tweet={tweet}
                                            onScrolledToBottom={onScrolledToBottom}
                                            isLastTweet={tweetsData.tweets.length - 1 === tweetIndex}
                                        />
                                    ))}
                                    {noTweetsUI}
                                    {isInfiniteTweetsLoading && <TweetsLoader />}
                                </>
                            )}></Await>
                    </Suspense>
                </div>
            )}
        </div>
    );
};

const TweetsLoader = () => {
    return (
        <div className="flex justify-center items-center h-full mt-4">
            <Loading />
        </div>
    );
};
export default MobileLiveFeed;
