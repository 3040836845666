const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const getCurrentYear = () => {
    return new Date().getFullYear();
};

export const formatDateWithHoursMinutes = (dateRef: Date) => {
    const date = new Date(dateRef);

    let hours = date.getHours();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    const month = monthNames[date.getMonth()];
    const dayDate = date.getDate();
    const year = date.getFullYear();

    const time = `${hours}:${minutes} ${amPm}`;
    const fullDate = `${month} ${dayDate}, ${year}`;

    return { time, date: fullDate };
};

export const CURRENT_YEAR = getCurrentYear();
