const AUTHENTICATION_TOKEN_KEY = 'authentication-token';

export const setAuthenticationToken = (token: string) => {
  localStorage.setItem(AUTHENTICATION_TOKEN_KEY, token);
};

export const getAuthenticationToken = (): string | null => {
  return localStorage.getItem(AUTHENTICATION_TOKEN_KEY);
};

export const removeAuthenticationToken = () => {
  localStorage.removeItem(AUTHENTICATION_TOKEN_KEY);
};
