import { FC } from 'react';
import styles from './RenderStats.module.css';

export type RenderStatsProps = {
    allProperties: boolean;
    rank: number;
    keyword: string;
    mentions: number;
    previousMentions: number;
    uniqueMentions: number;
    change: number;
    changePercentage: number;
    dominance: number;
    dominanceChange: number;
    active: boolean;
    isLoadingTweets: boolean;
    showUnique?: boolean;
    onReset: () => void;
    onClick: (keyword: string) => void;
};

export const RenderStats: FC<RenderStatsProps> = ({
    allProperties,
    rank,
    keyword,
    mentions,
    uniqueMentions,
    change,
    changePercentage,
    dominance,
    dominanceChange,
    active,
    isLoadingTweets,
    showUnique,
    onReset,
    onClick
}) => {
    return (
        <tr>
            <td className={`text-themeBlue font-inter font-normal text-sm text-left truncate ${styles.TableFirstElement}`}>
                <span className="cursor-pointer border-b border-gray-300" onClick={() => isLoadingTweets ? undefined : onClick(keyword) }>
                    {rank + ') ' + keyword}
                </span>
                {active && (
                    <button
                        className="cursor-pointer bg-transparent border-none p-0 m-0 text-black font-bold hover:bg-gray-100"
                        onClick={() =>  isLoadingTweets ? undefined : onReset()}>
                        (X)
                    </button>
                )}
            </td>
            <td>{change === null ? 'N/A' : change}</td>
            <td>{ mentions}</td>
            {allProperties && (
                <>
                    <td>{changePercentage === null ? 'N/A' : `${changePercentage.toFixed(3)}%`}</td>
                    <td>{dominance === null ? 'N/A' : `${dominance.toFixed(3)}%`}</td>
                    <td>{dominanceChange === null ? 'N/A' : `${dominanceChange.toFixed(3)}%`}</td>
                </>
            )}
        </tr>
    );
};
