import { create } from "zustand";
import { persist } from "zustand/middleware";

type StoreState = {
  engagementFilterEnabled: boolean;
  setEngagementFilterEnabled: (enabled: boolean) => void;
};

export const useStore = create(persist<StoreState>((set) => ({
  engagementFilterEnabled: false,
  setEngagementFilterEnabled: (enabled: boolean) => set({ engagementFilterEnabled: enabled }),
}), { name: "store" }));

export const useIsScored = () => useStore((state) => state.engagementFilterEnabled);
