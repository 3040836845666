import { useCallback, useEffect, useState } from "react";
import {NavLink, Outlet} from "react-router-dom";
import {client} from "../utils/client";

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export type AuthData = {
  user: {
    id: number;
    identifier: string;
    email: string;
    name: string;
    picture: string;
    role: Role;
  };
};

export const AdminTemplate = () => {

  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState<AuthData>();

  const checkAuth = useCallback(async () => {
    try {
      const response = await client.get(`/auth`);
      setAuthorized({user: response.data.user});
    } catch (error) {
      setAuthorized(undefined);
    } finally {
      setLoading(false);
    }
    setLoading(false)
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (loading) {
    return <LoadingTemplate />;
  }

  if (authorized?.user?.role !== Role.ADMIN) {
    return <ForbiddenTemplate />;
  }

  return <AuthorizedTemplate />;
};

const AuthorizedTemplate = () => (
    <>
      <header className="bg-blue-900 text-white py-4">
        <div className="container px-4 flex items-center justify-end gap-12">
          <nav className="flex items-center justify-between gap-12 w-full">
            <ul className="flex flex-row justify-start items-center gap-4">
              <li><NavLink to="/" end className={({isActive}) => isActive ? 'text-orange-500' : ''}>Client Zone</NavLink></li>
            </ul>
            <div className="flex flex-row justify-end items-center gap-12">
              <ul className="flex flex-row justify-end items-center gap-4">
                <li><NavLink to="/admin" end className={({isActive}) => isActive ? 'text-orange-500' : ''}>Stats</NavLink></li>
                {/*<li><NavLink to="/admin/tweeters" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Tweeters</NavLink></li>*/}
                <li><NavLink to="/admin/keywords" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Keywords</NavLink></li>
                <li><NavLink to="/admin/tweets" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Tweets</NavLink></li>
                <li><NavLink to="/admin/feed" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Feed</NavLink></li>
              </ul>
              <ul className="flex flex-row justify-end items-center gap-4">
                <li><NavLink to="/admin/keys" className={({isActive}) => isActive ? 'text-orange-500' : ''}>Keys</NavLink></li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="container py-12 px-4">
        <Outlet />
      </div>
    </>
);

const LoadingTemplate = () => (<div className="container py-12 px-4">
<h1>Authenticate</h1>
<p className="mb-12">Loading...</p>
</div>);

const ForbiddenTemplate = () => (<div className="container py-12 px-4">
<h1>Forbidden</h1>
<p className="mb-12">Access forbidden</p>
</div>);
