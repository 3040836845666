import * as React from 'react';
import { FC } from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { CreateGroupDialog } from './CreateGroupDialog';
import { client } from '../../utils/client';
import { useRevalidator } from 'react-router-dom';

type GroupDialogProps = {
  isOpen: boolean;
  keyword: string;
  selectedValue: string;
  onClose: () => void;
}

export type Group = {
  id: string;
  name: string;
  children: {
    keyword: string;
  }[];
};

export const GroupDialog: FC<GroupDialogProps> = ({
  isOpen,
  onClose,
  keyword,
}) => {
  const { revalidate } = useRevalidator();

  const [create, toggleCreate] = React.useReducer((s) => !s, false);

  const [groups, setGroups] = React.useState<Group[]>([]);

  React.useEffect(() => {
    client.get(`/admin/keyword/groups/list`).then((res) => {
      setGroups((res.data.groups || []));
    });
  }, []);

  const handleGroupCreate = React.useCallback((group?: Group) => {
    toggleCreate();
    if (group) {
      setGroups(val => [...val, group]);
      revalidate();
    }
  }, [revalidate]);

  const handleListItemClick = React.useCallback((value: string) => {
    client.put(`/admin/keyword/groups/${value}`, {
      keyword,
    }).then((res) => {
      onClose();
      revalidate();
    });
  }, [keyword, onClose, revalidate]);

  return (
    <>
      <Dialog onHide={onClose} visible={isOpen} header="Add keyword to group">
        <button onClick={toggleCreate} className='mb-4'>Create group</button>
        <ListBox filter onChange={(e) => handleListItemClick(e.value)} options={groups} optionLabel="name" optionValue="id" className="w-full md:w-14rem" />
      </Dialog>
      <CreateGroupDialog isOpen={create} onClose={handleGroupCreate} keyword={keyword} />
    </>
  );
}
